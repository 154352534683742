// eslint-disable-next-line
// @ts-ignore
import browserUpdate from 'browser-update'
import { ElLoading as Loading } from 'element-plus'
import { createApp } from 'vue'
import i18n from '@/i18n'
import '@/integrations/rollbar'
import '@/plugins'
import router from '@/router'
import '@/scss/app.scss'
import store from '@/store'
import App from './entrypoints/App.vue'

// test by appending `/#test-bu` to URLs
browserUpdate({
  required: {
    e: -3, // Edge
    f: -3, // Firefox
    o: -3, // Opera
    s: -3, // Safari
    c: -3, // Chrome
  },
})

createApp(App).use(i18n).use(router).use(store).use(Loading).mount('#app')
